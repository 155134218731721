import env from 'react-dotenv';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { getCookie, setCookie } from '../utilities/helpers';
import { getLUTFiltercategory, assignFrameCountGroup, hasMargin } from '../utilities/composition';
import { useCompositionTemplates, useFrami } from '../context';
import { useCallback } from 'react';
import { posthog } from 'posthog-js';
import { useAuth } from '../context';

const NAMESPACE_UUID = '417bd4eb-26f9-4af5-b9cc-e91a568a15c0';

const fbqStandardEvents = {
    add_payment_info: 'AddPaymentInfo',
    add_to_cart: 'AddToCart',
    begin_checkout: 'InitiateCheckout',
    view_item: 'ViewContent',
    login: 'Lead',
    purchase: 'Purchase',
    register: 'CompleteRegistration',
    add_to_wishlist: "AddToWishlist",
    page_view: "PageView",
    register: "CompleteRegistration",
}

export const googleConversionRates = {
    composition_select: { q: 0.75, a1: 0.0000802 },
    photo_upload: { q: 0.75, a1: 0.0007224 },
    ready_to_buy: {q: 0.5, a1: 0.003021304},
    add_to_cart: {q: 0.25, a1: 0.045176},
    payment: {q: 1, a1: 0.70},
    begin_checkout: { q: 0.2, a1: 0.08 },
    login: { q: 0.15, a1: 0.02736 },
}

const businessEvents = [
    'composition_select',
    'photo_upload',
    'ready_to_buy',
    'first_ready_to_buy',
    'add_coupon',
    'add_to_cart',
    'purchase',
    'begin_checkout',
    'login',
    'register',
    'add_to_wishlist',
    'page_view',
    'add_payment_info',
    'add_shipping_info',
    'view_item',
    'newsletter_signup',
    'signup',
    'welcome_video_close'
]

const compound_conversion_events = [
    'composition_select',
    'photo_upload',
    'ready_to_buy',
    'purchase',
    'begin_checkout',
    'add_to_cart',
    'add_to_wishlist',
    'add_payment_info',
    'add_shipping_info',
    'login',
    'newsletter_signup',
    'signup',
    'welcome_video_close'
]

export function calculateReadyToBuyValuePercent(number) {
    // var valuesMap = {
    //     1: 0.005 / 5,
    //     2: 0.01 / 5,
    //     3: 0.015 / 5,
    //     4: 0.015 / 5,
    // };

    // if (valuesMap[number] !== undefined) {
    //     return valuesMap[number];
    // } else {
        return calculateConversion('ready_to_buy', number);
    // }
}

export const calculateConversion = (event_name, n) => {
    if (googleConversionRates.hasOwnProperty(event_name)) {
        const { q, a1 } = googleConversionRates[event_name];
        return a1 * q ** (n - 1)
    }
    return 0;
}

const getEventAndTracking = (event_name) => {
    if (fbqStandardEvents.hasOwnProperty(event_name)) {
        return [fbqStandardEvents[event_name], 'track'];
    } else {
        return [event_name, 'trackCustom'];
    }
}

const hashUserData = async (value) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(value);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
}

const normalizeEmail = (email) => {
    let normalizedEmail = email.trim().toLowerCase();
    if (normalizedEmail.endsWith("@gmail.com") || normalizedEmail.endsWith("@googlemail.com")) {
        // Usuń wszystkie kropki przed znakiem @ dla adresów Gmail.
        const parts = normalizedEmail.split('@');
        normalizedEmail = parts[0].replace(/\./g, '') + '@' + parts[1];
    }
    return normalizedEmail;
}

const normalizePhoneNumber = (phoneNumber) => {
    const digits = phoneNumber.replace(/\D/g, '');
    return `+${digits}`;
}

const normalizeName = (name) => {
    return name.replace(/[\d\s\W_]+/g, '').toLowerCase().trim();
}


export async function pushEventToGTM(event_name, user = null, event_parameters = {}, eventID = null) {
    let event_at = new Date();

    if (!eventID) {
        eventID = uuidv4();
    }


    console.log('pushEventToGTM', event_name, event_parameters, user);

    if (env.REACT_APP_PUBLIC_POSTHOG_KEY && window.posthog && event_name !== 'init_tags') {
        const event_params = {
            uuid: eventID,
            'ad_personalization': getCookie('ad_personalization'),
            'ad_user_data': getCookie('ad_user_data'),
            'ad_storage': getCookie('ad_storage'),
            'analytics_storage': getCookie('analytics_storage'),
            ...event_parameters,
        };
        console.log('pushEventToGTM->posthog', event_name, event_params)

        if (event_name === 'page_view') {
            posthog.capture('$pageview', event_params, {
                timestamp: event_at,
            });        
        }
        else {
            posthog.capture(event_name, event_params, {
                timestamp: event_at,
            });        
        }
    }

    const fbpCookie = getCookie('_fbp');

    const fbcCookie = getCookie('_fbc');


    if (env.GTM_ENABLED && window.gtag && (businessEvents.includes(event_name) || event_name === 'init_tags')) {
        const user_data = {};

        if (fbpCookie) {
            user_data.fbp = fbpCookie;
        }
        if (fbcCookie) {
            user_data.fbc = fbcCookie;
        }

        // Jeśli user jest dostępny, dodajemy jego dane
        if (user?.email && user.email.includes('@')) {
            if (user.email) {
                user_data.sha256_email_address = await hashUserData(normalizeEmail(user.email));
            }
            if (user.phone_number) {
                user_data.sha256_phone_number = await hashUserData(normalizePhoneNumber(user.phone_number));
            }
            user_data.address = {}

            if (user.first_name) {
                user_data.address.sha256_first_name = await hashUserData(normalizeName(user.first_name));
            }
            if (user.last_name) {
                user_data.address.sha256_last_name = await hashUserData(normalizeName(user.last_name));
            }
            if (user.address) {
                user_data.address.sha256_street = await hashUserData(normalizeName(user.address));
            }
            if (user.city) {
                user_data.address.city = user.city;
            }
            if (user.postal_code) {
                user_data.address.postal_code = user.postal_code;
            }
            if (user.country) {
                user_data.address.country = user.country;
            }
        }

        // Przygotowujemy event_params
        const event_params = {
            'timestamp': event_at.toISOString(),
            'sent_at': event_at.toISOString(),
            'uuid': eventID,
            'event_id': eventID,
            ...event_parameters,
            user_data, // Zawsze dodajemy user_data
        };

        console.log('pushEventToGTM->gtag', event_name, event_params)
        window.gtag('event', event_name, event_params);
    }

    if (
        env.PIXEL_ID &&
        getCookie('ad_personalization') === 'granted' &&
        businessEvents.includes(event_name)
    ) {

        const user_data = {};

        if (fbpCookie) {
            user_data.fbp = fbpCookie;
        }
        if (fbcCookie) {
            user_data.fbc = fbcCookie;
        }

        if (user?.email && user.email.includes('@')) {
            if (user.email) {
                user_data.em = await hashUserData(normalizeEmail(user.email));
            }
            if (user.phone_number) {
                user_data.ph = await hashUserData(normalizePhoneNumber(user.phone_number));
            }
            if (user.first_name) {
                user_data.fn = await hashUserData(normalizeName(user.first_name));
            }
            if (user.last_name) {
                user_data.ln = await hashUserData(normalizeName(user.last_name));
            }
            if (user.city) {
                user_data.ct = user.city.toLowerCase();
            }
            if (user.postal_code) {
                user_data.zp = user.postal_code;
            }
            if (user.country) {
                user_data.country = user.country.toLowerCase();
            }
        }

        if (user?.pk) {
            user_data.external_id = user.pk;
        }

        // Przygotowujemy event_params
        const event_params = {
            ...event_parameters,
        };

        const contents = event_params?.items?.map(item => ({
            id: item.item_id,
            quantity: item.quantity,
        }));
        
        event_params.contents = contents;


        const [eventName, tracking] = getEventAndTracking(event_name);

        console.log('pushEventToGTM->fbq', eventName, event_params, user_data)

        if (window.fbq) {
            // Jeśli mamy dodatkowe dane użytkownika, ponownie inicjalizujemy fbq z tymi danymi
            if (Object.keys(user_data).length > 0) {
                window.fbq('init', env.PIXEL_ID, user_data);
            }

            window.fbq(tracking, eventName, event_params, { 'eventID': eventID });
            if (compound_conversion_events.includes(event_name)) {
                window.fbq(tracking, 'compound_conversion', event_params);
            }
        }
        else {
            console.warn('Pixel Facebooka nie został zainicjowany, ponieważ window.fbq jest niezdefiniowane.');
        }

    }
}

export function setUserIdInGTM(userId) {
    if (env.GTM_ENABLED && window.gtag) {
        window.gtag('config', env.GTAG_ID, {
            'user_id': userId
        });
    }
}

function pushConsentToGTM(consent_action, consent_parameter = {}) {
    if (env.GTM_ENABLED && window.gtag) {
        console.log('consent', consent_action, consent_parameter)
        window.gtag('consent', consent_action, {
            ...consent_parameter
        });
    }
}

export function rejectCookies() {
    pushConsentToGTM('default', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied'
    })
}

export function defaultConsent() {
    const adStorage = getCookie('ad_storage') || 'denied';
    const adUserData = getCookie('ad_user_data') || 'denied';
    const adPersonalization = getCookie('ad_personalization') || 'denied';
    const analyticsStorage = getCookie('analytics_storage') || 'denied';

    pushConsentToGTM('default', {
        'ad_storage': adStorage,
        'ad_user_data': adUserData,
        'ad_personalization': adPersonalization,
        'analytics_storage': analyticsStorage
    })
}

export function acceptCookies(cookieData = null) {
    const adStorage = cookieData && !cookieData.marketing ? 'denied' : 'granted';
    const adUserData = cookieData && !cookieData.marketing ? 'denied' : 'granted';
    const adPersonalization = cookieData && !cookieData.marketing ? 'denied' : 'granted';
    const analyticsStorage = cookieData && !cookieData.analytics ? 'denied' : 'granted';
    setCookie('ad_storage', adStorage, 365)
    setCookie('ad_user_data', adUserData, 365)
    setCookie('ad_personalization', adPersonalization, 365)
    setCookie('analytics_storage', analyticsStorage, 365)

    setCookie('cookieconsent_status', 'savesettings', 365)
    setCookie('cookieconsent_status_marketing', cookieData?.marketing ? 'allow': 'deny', 365)
    setCookie('cookieconsent_status_analytics', cookieData?.analytics ? 'allow': 'deny', 365)
    setCookie('cookieconsent_status_tech', 'allow', 365)

    pushConsentToGTM('update', {
        'ad_storage': adStorage,
        'ad_user_data': adUserData,
        'ad_personalization': adPersonalization,
        'analytics_storage': analyticsStorage
    })
}

function getAWINMerchantId() {
    const host = window.location.hostname;
    const domainSuffix = host === 'localhost' ? 'com' : host.split('.').pop();

    const merchantId = (domainSuffix === 'it' ? '105975' : '105977');
    return merchantId;
}

export function addAWINScript() {
    const awinScript = document.createElement('script');
    awinScript.src = `https://www.dwin1.com/${getAWINMerchantId()}.js`;
    document.head.appendChild(awinScript);
}

export async function pushOrderToAWIN(order, user) {
    // Upewnij się, że kod jest wykonywany po stronie klienta
    if (typeof window !== 'undefined') {
        const merchantId = getAWINMerchantId();

        const customer_acquisition = user?.events.payment > 0 ? 'returning' : 'new';
        // Tworzenie i dodawanie tagu <img>
        const img = document.createElement('img');
        img.src = `https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=${merchantId}&amount=${order.price_before_discount_net}&cr=${order.currency}&ref=${order.id}&parts=DEFAULT:${order.price_before_discount_net}&vc=${order.coupon?.code}&ch=aw&customeracquisition=${customer_acquisition}`;
        img.border = '0';
        img.width = '0';
        img.height = '0';
        img.style.display = 'none'; // Ukryj obrazek
        img.alt = '';
        document.body.appendChild(img);

        // Ustawianie zmiennych AWIN
        window.AWIN = window.AWIN || {};
        window.AWIN.Tracking = window.AWIN.Tracking || {};
        window.AWIN.Tracking.Sale = {};

        window.AWIN.Tracking.Sale.amount = order.price_before_discount_net;
        window.AWIN.Tracking.Sale.orderRef = order.id;
        window.AWIN.Tracking.Sale.parts = `DEFAULT:${order.price_before_discount_net}`;
        window.AWIN.Tracking.Sale.voucher = order.coupon?.code;
        window.AWIN.Tracking.Sale.currency = order.currency;
        window.AWIN.Tracking.Sale.channel = 'aw';
        window.AWIN.Tracking.Sale.customerAcquisition = customer_acquisition;

    } else {
        // Jeśli kod jest wykonywany po stronie serwera
        console.warn('AWIN tracking nie został zainicjowany, ponieważ window jest niezdefiniowane.');
    }
}

export function useTracking() {
    const { currentFrami, memoizedImageFilters } = useFrami();
    const { generate_sku } = useCompositionTemplates();
    const { user, temporaryUser} = useAuth();

    const pushEvent = useCallback(async(event_name, event_parameters={}, eventID=null, user_override=null) => {
        if (user_override) {
            await pushEventToGTM(event_name, user_override, event_parameters, eventID);
        }
        else if (user) {
            await pushEventToGTM(event_name, user, event_parameters, eventID);
        }
        else {
            await pushEventToGTM(event_name, temporaryUser, event_parameters, eventID);
        }
    }, [user, temporaryUser]);

    const pushPurchaseEvent = useCallback(async (order, express = false, event_name='purchase') => {
        console.log('pushPurchaseEvent', order, express)

        if (!order) return;
        const eventID = uuidv5(order.id.toString(), NAMESPACE_UUID);

        const user_data = {
            email: order.email, 
            phone_number: order.shipping_phone_number, 
            first_name: order.shipping_first_name, 
            last_name: order.shipping_last_name, 
            city: order.shipping_city, 
            postal_code: order.shipping_postal_code, 
            country: order.shipping_country,
            id: user?.pk || temporaryUser?.pk,
        };

        await pushEventToGTM(event_name, user_data, {

            currency: order.currency,
            value: order.price_after_discount_net,
            transaction_id: order.id,
            coupon: order.coupon?.code,
            shipping: order.delivery?.cost,
            tax: order.price_after_discount_gross - order.price_after_discount_net,
            items: order.compositions.map((obj) => {
                const lutFilterCategory = getLUTFiltercategory(memoizedImageFilters.get(obj?.lut_filter))
                const framesCountGroup = assignFrameCountGroup(obj?.frames_json.length);
                const margin = hasMargin(currentFrami.default_passepartout_width)
                return ({
                    item_id: generate_sku(obj?.category, lutFilterCategory, framesCountGroup, margin),
                    item_brand: "Framky",
                    item_category: obj?.category,
                    item_category2: lutFilterCategory,
                    item_category3: framesCountGroup,
                    item_category4: margin,
                    quantity: 1,
                })
            }),
            price_before_discount_gross: order.price_before_discount_gross,
            price_before_discount_gross_in_pln: order.price_before_discount_gross_in_pln,
            price_after_discount_gross: order.price_after_discount_gross,
            price_after_discount_gross_in_pln: order.price_after_discount_gross_in_pln,
            price_after_discount_net: order.price_after_discount_net,
            price_before_discount_net: order.price_before_discount_net,
            price_in_PLN: order.price_after_discount_gross_in_pln,
            price_in_PLN_netto: order.price_after_discount_net_in_pln,
            express: express,
        }, eventID);

        if (env.AWIN_ENABLED) {
            pushOrderToAWIN(order, user);
        }
    }, [memoizedImageFilters, currentFrami]);

    return {pushEvent, pushPurchaseEvent};
}