import React, { Suspense, useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/browser";
import env from 'react-dotenv';
import 'tailwindcss/dist/tailwind.min.css';
import 'rc-slider/assets/index.css';
import './index.css';

import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import CacheBuster from 'react-cache-buster';
import { CookiesProvider } from 'react-cookie';
import { IntlProvider } from 'react-intl';
import { injectStyle } from 'react-toastify/dist/inject-style';

import packageInfo from '../package.json';
import Loader, { LoaderWrapper } from './components/common/Loader';
import { addGTag } from './utilities/gtag';
import { lazyWithPreload } from './utilities/lazy-with-preload';
import { addAWINScript } from './data/gtm';
injectStyle();

const App = lazyWithPreload(() => import(/* webpackChunkName: 'App' */ './App'));

for (const [key, value] of Object.entries(env)) {
    console.log(`${key}: ${value}`);
    if (value === 'true') env[key] = true;
    if (value === 'false') env[key] = false;
    if (value === 'null') env[key] = null;
    if (!isNaN(Number(value))) env[key] = Number(value);
}

console.log('ENV', env);


if (env.SENTRY_ENABLED) {
    Sentry.init({
        dsn: 'https://36eb73745f2f45a49f9b33e3afe33120@o1031878.ingest.sentry.io/6002013',
        integrations: [new Sentry.BrowserTracing()],
        environment: env.ENV_NAME,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    });
}

if (env.AWIN_ENABLED) {
    addAWINScript();
}

if (env.GTM_ENABLED && env.GTAG_ID) {
    // const gtagId = env.GTAG_ID;

    const host = window.location.hostname;
    // const domainSuffix = host === 'localhost' ? 'com' : host.split('.').pop();
    // const gtagId = containerIds[domainSuffix];
    // const taggingServer = `https://gtm.framky.${domainSuffix}/`;
    if (host !== 'localhost') {
        addGTag();
    }
}

if (env.REACT_APP_PUBLIC_POSTHOG_KEY && env.REACT_APP_PUBLIC_POSTHOG_HOST) {
    const host = window.location.hostname;
    const domainSuffix = host === 'localhost' ? 'com' : host.split('.').pop();
    posthog.init(env.REACT_APP_PUBLIC_POSTHOG_KEY, {
        api_host: `https://api2.framky.${domainSuffix}`,
        ui_host: env.REACT_APP_PUBLIC_POSTHOG_HOST,
        autocapture: false,
        enable_heatmaps: false,
        // advanced_disable_feature_flags_on_first_load: true,
        persistence: 'localStorage+cookie',
    });
    window.posthog = posthog;
}

const locale = navigator.language.substr(0, 2).toLowerCase();

const loadLocaleData = (locale) => {
    switch (locale) {
        case 'pl':
            return import(/* webpackChunkName: 'lang_pl' */ './i18n/pl.json');
        case 'de':
            return import(/* webpackChunkName: 'lang_de' */ './i18n/de.json');
        case 'fr':
            return import(/* webpackChunkName: 'lang_fr' */ './i18n/fr.json');
        case 'it':
            return import(/* webpackChunkName: 'lang_it' */ './i18n/it.json');
        case 'da':
            return import(/* webpackChunkName: 'lang_da' */ './i18n/da.json');
        case 'nl':
            return import(/* webpackChunkName: 'lang_nl' */ './i18n/nl.json');
        case 'sv':
            return import(/* webpackChunkName: 'lang_sv' */ './i18n/sv.json');
        default:
            return import(/* webpackChunkName: 'lang_en' */ './i18n/en.json');
    }
};

document.documentElement.setAttribute('lang', locale);

const Root = () => {
    const [messages, setMessages] = useState(null);

    App.preload();

    useEffect(() => {
        loadLocaleData(locale).then((data) => {
            setMessages(data.default);
        });
    }, [locale]);

    if (!messages) {
        return (
            <LoaderWrapper>
                <Loader />
            </LoaderWrapper>
        );
    }

    return (
        <IntlProvider locale={locale} messages={messages}>
            <PostHogProvider client={posthog}>
                <CookiesProvider>
                    <CacheBuster
                        currentVersion={packageInfo.version}
                        isEnabled={env.ENV_NAME === 'Production'}
                        isVerboseMode={false} //If true, the library writes verbose logs to console.
                        metaFileDirectory={'../public'}
                        loadingComponent={
                            <LoaderWrapper>
                                <Loader />
                            </LoaderWrapper>
                        }
                    >
                        <App />
                    </CacheBuster>
                </CookiesProvider>
            </PostHogProvider>
        </IntlProvider>
    );
};

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Suspense fallback={<LoaderWrapper><Loader /></LoaderWrapper>}>
            <Root />
        </Suspense>
    </React.StrictMode>
);
