import React, { createContext, useContext } from 'react';

export const PromotionContext = createContext({
    promotion: null,
    isPromotionActive: false,
    setIsPromotionActive: () => {},
    hidePromo: () => {},
});

export function usePromotion() {
    return useContext(PromotionContext);
}
